<template>
  <portal selector="#connected-agent-info">
    <div :id="visitorId" class="pa-agents arrow-left" :style="styleObject">
      <div style="position: absolute; top: 20px; right: 20px; z-index: 60002" class="pa-agents-close-icon" @click="closeConnectedAgentsInfo">
        <close-icon :width="12" :height="12"></close-icon>
      </div>
      <scroll-view class="pa-agents-scroll-area">
        <div class="pa-agents-box">
          <div class="pa-agents-info" v-for="(agent, index) in agents" :key="index">
            <div class="pa-agents-info-missed" v-if="agent.type === 'missed'">missed</div>
            <div v-else class="pa-agents-info-image">
              <Avatar class="pa-agents-info-image-avatar" size="45px" :photoURL="agentImage(agent.agentId)" :name="agentName(agent.agentId)" />
            </div>
            <div class="pa-agents-info-summary" v-if="agent.type === 'missed'">
              <div class="pa-agents-info-summary-session-time">
                {{ timeInfo(agent) }}
              </div>
            </div>
            <div v-else class="pa-agents-info-summary">
              <div class="pa-agents-info-summary-agent-name">
                {{ agentName(agent.agentId) }}
              </div>
              <div class="pa-agents-info-summary-session-time">
                {{ timeInfo(agent) }}
              </div>
            </div>
          </div>
        </div>
      </scroll-view>
    </div>
  </portal>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import { mapGetters } from 'vuex'
import { Portal } from '@linusborg/vue-simple-portal'
import Avatar from '@/components/Avatar'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import CloseIcon from '@/components/icons/CloseIcon.vue'
export default {
  name: 'ConnectedAgentInfo',
  props: {
    agents: {
      type: Array
    },
    visitorId: {
      type: String
    }
  },
  data() {
    return {
      top: '50%',
      left: '300%',
      opacity: 0,
      display: 'none',
      currentVisitorId: null,
      zIndex: 0
    }
  },
  components: {
    Portal,
    Avatar,
    ScrollView,
    CloseIcon
  },
  created() {
    this.$serverBus.$on('show-connected-agent-info', (info) => {
      this.currentVisitorId = info.visitorId
      const position = this.getPosition(info.visitorId)

      if (position && info.visitorId === this.visitorId) {
        this.top = position.y.toString()
        this.left = position.x.toString()
        this.opacity = 1
        this.zIndex = 60000
      } else {
        this.top = '50%'
        this.left = '100%'
        this.currentVisitorId = null
        this.opacity = 0
        this.zIndex = 0
      }
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('show-connected-agent-info')
  },
  computed: {
    ...mapGetters({
      users: 'users'
    }),
    styleObject() {
      return {
        opacity: this.opacity,
        top: this.top.indexOf('%') > -1 ? this.top : `${this.top}px`,
        left: this.left.indexOf('%') > -1 ? this.left : `${this.left}px`
      }
    }
  },
  methods: {
    agentImage(agentId) {
      const agent = this.users.find((x) => x.id === agentId)

      if (agent && agent.image) {
        return agent.image
      }

      return ''
    },
    agentName(agentId) {
      const agent = this.users.find((x) => x.id === agentId)

      if (!agent) {
        return ''
      }

      return agent.name
    },
    getPosition(id) {
      const el = document.getElementById(`incoming-request-${id}`)
      const popup = document.getElementById(id)
      if (el && popup) {
        const rect = el.getBoundingClientRect()
        const rect2 = popup.getBoundingClientRect()

        const halfHeight = rect.height / 2
        const halfHeightPopup = rect2.height / 2
        const centerPosition = rect.top + halfHeight

        const newWidth = rect.x + rect.width - 20

        const newHeight = centerPosition - halfHeightPopup
        return {
          x: newWidth,
          y: newHeight
        }
      }
    },
    closeConnectedAgentsInfo() {
      this.top = '50%'
      this.left = '300%'
      this.currentVisitorId = null
      this.opacity = 0
      this.zIndex = 0
    },
    timeInfo(agent) {
      let info = ''
      if (agent.type === 'missed' && agent.missedCallDate) {
        const missedCallDate = dayjs.unix(agent.missedCallDate.seconds).locale(this.$i18n.locale)
        info = `${missedCallDate.locale(this.$i18n.locale).format('L')} | ${missedCallDate.format('HH:mm')}`
      } else if (agent.sessionStartTime && agent.sessionEndTime) {
        const sessionStartTime = dayjs.unix(agent.sessionStartTime.seconds).locale(this.$i18n.locale)
        const sessionEndTime = dayjs.unix(agent.sessionEndTime.seconds).locale(this.$i18n.locale)
        info = `${sessionStartTime.locale(this.$i18n.locale).format('L')} | ${sessionStartTime.format('HH:mm')} - ${sessionEndTime.format(
          'HH:mm'
        )} | ${sessionEndTime.from(sessionStartTime, true)}`
      }

      return info
    }
  }
}
</script>

<style lang="scss">
.pa-agents.arrow-left:after {
  content: ' ';
  position: absolute;
  z-index: 60001;
  left: 5px;
  //top: 96px;
  top: 46%;
  border-top: 5px solid transparent;
  border-right: 5px solid black;
  border-left: none;
  border-bottom: 5px solid transparent;
}
.pa-agents {
  position: fixed;

  width: 342px;
  //height: 205px;
  height: auto;
  max-height: 205px;

  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px;
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in, left 0.3s ease-out, top 0.3s ease-out;
  cursor: default;

  &-close-icon {
    height: 20px;
    width: 20px;
    border: 1px solid #00a9f5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background-color: #00a9f5;
    cursor: pointer;
  }

  &-close-icon:active {
    transform: scale(0.95);
  }

  &-box {
    padding: 10px;
  }

  &-scroll-area {
    width: 322px;
    height: auto;
    max-height: 185px;
    background-color: #e6ecf1;
    border: 1.5px solid #00a9f5;
    border-radius: 6px;
  }

  &-info {
    display: flex;
    padding: 2px 10px 2px 10px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    &-missed {
      width: 45px;
      height: 45px;
      margin-right: 20px;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background: #f05541;
      border: 1px solid #ffffff;
      border-radius: 50%;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }

    &-image {
      width: 45px;
      height: 45px;
      margin-right: 20px;
      margin-left: 10px;
      &-avatar {
        border: 1px solid #1c8439;
        border-radius: 50%;
      }
    }
    &-summary {
      display: flex;

      flex-direction: column;
      &-agent-name {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #262629;
      }
      &-session-time {
        font-family: 'Lato';
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 17px;
        color: #939393;
      }
    }
  }
}
</style>
